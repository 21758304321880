import React from "react";
import { UserIcon } from "@heroicons/react/20/solid";
import { useUser, useClerk, useAuth, useOrganizationList } from "@clerk/clerk-react";

const NavMenu = () => {
    const { user, } = useUser();
    const { orgRole, signOut } = useAuth();
    const clerk = useClerk();
    const {
        organizationList,
        setActive
    } = useOrganizationList();

    return (
        <div className="menu menu-horizontal gap-3">
            <div className="dropdown dropdown-end">
                <label tabIndex={0}
                    className="btn btn-ghost btn-circle avatar">
                    <div className="w-8 rounded-full">
                        {
                            user?.profileImageUrl ? <img src={user.profileImageUrl} alt={"User Settings"} className="rounded-full" /> : <UserIcon className="h-8 w-8 text-gray-500" />
                        }
                    </div>
                </label>
                <ul tabIndex={0}
                    className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-200 rounded-box w-52">
                    <li className="menu-title">
                        <span className="pl-2">{user?.fullName ?? user?.primaryEmailAddress?.emailAddress ?? "UNKNOWN"}</span>
                    </li>
                    <li>
                        <button className="justify-between w-full" onClick={() => clerk.openUserProfile()}>
                            Profile
                        </button>
                    </li>
                    <li>
                        <button className="w-full">Settings</button>
                    </li>
                    {orgRole === 'admin' && <section>
                        <li className="menu-title">
                            <span className="pl-2">Admin</span>
                        </li>
                        <li>
                            <button className="w-full" onClick={() => clerk.openOrganizationProfile()} >Users</button>
                        </li>
                    </section>}
                    <li className="menu-title">
                        <span className="pl-2">Organization</span>
                    </li>
                    {organizationList?.map(({ organization }) => (
                        <li key={organization.id} className={clerk.organization?.id === organization.id ? 'disabled border-l' : ''}>
                            {clerk.organization?.id === organization.id ?
                                <span>{organization.name}</span> :
                                <button className="w-full" onClick={() => setActive({ organization: organization })}>{organization.name}</button>}

                        </li>
                    ))}
                    <hr />
                    <li>
                        <button className="w-full" onClick={() => signOut()}>
                            Log Out
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

const Nav = () => {
    const { isSignedIn, isLoaded } = useUser();

    return (
        <nav className="fixed w-full z-20 top-0 left-0 border-b bg-base-200">
            <div className="max-w-screen-xl flex items-center justify-between mx-auto">
                <div className="flex-1">
                    <a href="https://www.DeployPulse.com/" className="btn btn-ghost normal-case">
                        <img src="DeployPulse_Logo.svg" className="h-6 mr-3" alt="Deployo Pulse Logo" />
                        <span className="self-center text-lg whitespace-nowrap font-logo">Deploy Pulse</span>
                    </a>
                </div>
                <div className="flex-none gap-2">
                    {
                        isLoaded && isSignedIn ? <NavMenu /> : null
                    } </div>
            </div>
        </nav >
    );
}

export default Nav;
