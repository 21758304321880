import './App.css';
import { ClerkProvider, SignedOut, SignIn, SignedIn, ClerkLoaded, ClerkLoading } from "@clerk/clerk-react";
import Nav from './Nav';
import TokenDisplay from './TokenDisplay';

const clerk_pub_key = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY as string;

function App() {
  return (
    <ClerkProvider
      publishableKey={clerk_pub_key}
    >
      <ClerkLoading>
        <div>loading...</div>
      </ClerkLoading>
      <ClerkLoaded>
        <div className="App mt-16">
          <Nav />
          <SignedOut>
            <div className='flex justify-center mt-10'>
              <SignIn />
            </div>
          </SignedOut>
          <SignedIn>

            <header className="App-header">
              Hello World
            </header>
            <TokenDisplay/>
          </SignedIn>
        </div>
      </ClerkLoaded>
    </ClerkProvider>
  );
}

export default App;
