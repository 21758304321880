import { useAuth } from "@clerk/clerk-react";
import React from "react";

function TokenDisplay() {
    const [token, setToken] = React.useState('');
    const { getToken } = useAuth();

    React.useEffect(() => {
        const fetchToken = async () => {
            const result = await getToken({ template: 'DeployPulse_API' });
            if(result){
                setToken(result);
            }
        }

        fetchToken();
    }, [getToken]);

    return (
        <input type="text" value={token} readOnly={true} className="input input-bordered" />
    );
}

export default TokenDisplay;
